import React, { useEffect, useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component";
import { IsAdmin } from "../../context/IsAdmin";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase-config";
import PaypalButton from "./PaypalButton";
import { Timestamp } from 'firebase/firestore'
import axios from "axios";
import { endpoint } from "../../context/Endpoint";

interface IPriceCard {
    data:IPriceCardData;
    allSections:Option[];
    OnSave: (data:IPriceCardData) => void;
    OnDelete: ( ) => void;
}
interface IPriceCardData {
    title:string;
    price?:number;
    duration:any;
    description:string;
    visibility:boolean;
    courses:string[];
}


const PriceCard: React.FC<IPriceCard> = (priceCard) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [data, setData] = useState<any>({});
    const [selected, setSelected] = useState([]);
    const [purchase, setPurchase] = useState(false);
    const [userData, setUserData] = useState<any>();

    const OnLoadPage = () => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                axios.get(`${endpoint}/users/${user.uid}`).then((response) => {
                setUserData(response.data);
            });
            await IsAdmin().then(response => { setIsAdmin(response); });
            }
        });
        setData(priceCard.data);
    }

    useEffect(()=>{console.log(userData);}, [userData]);
    const EnabledEdit = () => { 
        if(purchase)
            return;
        setIsEditing(!isEditing);
    }
    const EditPrice = (parameter:string, value:any) => setData({...data, [parameter]:value});    
    const GetOptionsByCourses = (course:string[]) => priceCard.allSections?.filter((x: any) => course?.includes(x.value));
    const OnSave = (data:IPriceCardData)=>{
        setIsEditing(false);
        priceCard.OnSave(data);
    }
    const OnClick = (data:IPriceCardData)=>{
        setPurchase(!purchase);        
    }
    const OnPurchaseDone = () => {
        let newDate = Timestamp.fromDate(addMonth(new Date(Timestamp.now().toMillis()), data.duration));  
        let newSubscription = {
            provider:"Paypal",
            plan_name:`ORVO - ${data.title}`,
            product_id:data.id,
            subscription_id: crypto.randomUUID(),
            next_billing_time:newDate,
        } 
        let updatedUser = { ...userData };
        if(typeof (updatedUser.subscriptions) === 'undefined'){
            updatedUser.subscriptions = [newSubscription];
        } else {
            updatedUser.subscriptions?.push(newSubscription);
        }
        axios.put(`${endpoint}/users/${userData?.id}`, updatedUser).then(()=>{console.log("Se agrego la subscripcion al usuario"); }).catch((error) => console.error(error)); 
    }
    const OnPurchaseCancelled = () => console.log("Se cancelo la compra");

    const addMonth = (date:any, months:number):Date => {
      var d = date.getDate();
      date.setMonth(date.getMonth() + +months);
      if(date.getDate() != d)
        date.setDate(0);
      return date;
    }


    useEffect(()=>{EditPrice("courses", selected.map((x:any) => x.value))}, [selected]);
    
    useEffect(OnLoadPage, []);

    return <main>
        <div className="price">      
                { isAdmin && <img className='edit' src={process.env.PUBLIC_URL + "/img/edit.png"} onClick = {EnabledEdit}/>}
                { isAdmin && <img className='delete' src={process.env.PUBLIC_URL + "/img/delete-white.png"} onClick={priceCard.OnDelete}/>}
                { isAdmin && !data.visibility && <span className='invisible'>Visible solo por admin</span>}
                                  
                { 
                    !purchase ? <> 
                        <div className={isEditing ? 'modify' : 'get'}>
                        { isEditing && <span>Editar plan</span>}

                        { !isEditing ? <span>{data.title}</span> : <input type="text" name='title' value={data.title} placeholder='Titulo' onChange={(e)=> { EditPrice(e.target.name, e.target.value) }} /> }
                        
                        { !isEditing ? <span>{`$${data.price} USD`}</span> : <input type="text" name='price' value={data.price} placeholder='Precio (USD)'  onChange={(e)=> { EditPrice(e.target.name, e.target.value) }} /> }

                        { !isEditing ? <span>{`${data.duration} Meses`}</span> : <input type="text" name='duration' value={data.duration} placeholder='Duracion (Meses)'  onChange={(e)=> { EditPrice(e.target.name, e.target.value) }} /> }

                        { !isEditing ? <span>{data.description}</span> : <input type="text" name='description' value={data.description} placeholder='Descripcion'  onChange={(e)=> { EditPrice(e.target.name, e.target.value) }} /> }

                        { isEditing && <div className="multi-select"> <MultiSelect options={priceCard.allSections} value={GetOptionsByCourses(data.courses)} onChange={setSelected} labelledBy="Select" /> </div> }
                        
                        { isEditing && <div> <span>Visible: </span> <input type="checkbox" name='visibility' checked={data.visibility} onChange={(e)=> {EditPrice(e.target.name, e.target.checked)}} /> </div> }
                        </div>
                        <input type="button" value={isEditing ? "Guardar" : "Adquirir"} onClick={ ()=>{ isEditing ? OnSave(data) : OnClick(data)}  } />
                    </>
                    : <>
                        <div className={"purchase"}>
                            <PaypalButton totalValue={data.price} invoice={`Subscripcion a ${data.title}-ORVO`} onSuccess={OnPurchaseDone} onCancelled={OnPurchaseCancelled} />
                        </div>
                        <input type="button" value={"Cancelar"} onClick={ ()=>{ OnClick(data)}  } />
                    </>
                }
            </div>
    </main>
}
export default PriceCard;