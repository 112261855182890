import { useEffect, useState } from 'react';
import ExpandableBox from './layout/ExpandableBox';
import './styles/courseTemplate.css'
import { useNavigate, useParams } from "react-router-dom"
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';
import { IsAdmin } from '../context/IsAdmin';
import axios from 'axios';
import { endpoint } from '../context/Endpoint';
import { useTranslation } from 'react-i18next';
import Loading from '../context/Loading';
import { Timestamp } from 'firebase/firestore';
import { SimulatorModal } from './common/SimulatorModal';

export default function CourseTemplate() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const [userData, setUserData] = useState<any>({});
    const [pricing, setPricing] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenSelectSimulatorModal, setIsOpenSelectSimulatorModal] = useState(false);

    const onLoadPage = () => {
        axios.get(`${endpoint}/courses/${id}`).then(response => {setData(response.data); setIsLoading(false)});
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);
                await IsAdmin().then(response => { setIsAdmin(response); });
                axios.get(`${endpoint}/users/${user.uid}`).then((response) => { setUserData(response.data) });
                axios.get(`${endpoint}/pricing`)
                .then(res => setPricing(res.data))
                .catch(err => console.error(err));
            }
        });
    };

    const handleLevelTitleChange = (moduleIndex: number, newTitle: string) => {
        const updatedData = {
            ...data,
            levels: data?.levels?.map((module:any, index:any) => {
                if (index === moduleIndex) {
                    return { ...module, title: newTitle };
                }
                return module;
            })
        };
        setData(updatedData);
    };

    const handleLevelActiveChange = (moduleIndex: number, newValue: boolean) => {
        const updatedData = {
            ...data,
            levels: data?.levels?.map((module:any, index:any) => {
                if (index === moduleIndex) {
                    return { ...module, active: newValue };
                }
                return module;
            })
        };
        setData(updatedData);
    };

    const addCase = (moduleIndex: number) => {
        const updatedData = {
            ...data,
            levels: data?.levels?.map((module:any, index:any) => {
                if (index === moduleIndex) {
                    return { ...module, cases: [...module.cases, { title: "New Case", bubbles:[] }] };
                }
                return module;
            })
        };
        setData(updatedData);
    };

    const deleteCase = (moduleIndex: number, caseIndex: number) => {
        const updatedData = {
            ...data,
            levels: data?.levels?.map((module:any, index:any) => {
                if (index === moduleIndex) {
                    return { ...module, cases: module?.cases?.filter((_:any, i:any) => i !== caseIndex) };
                }
                return module;
            })
        };
        setData(updatedData);
    };
    const addModule = () => {
        const newModule = { title: "New Module", active: true, cases: [{ title: "New Case", bubbles:[] }] };
        const updatedData = {
            ...data,
            levels: [...data?.levels, newModule]
        };
        setData(updatedData);
    };
    const deleteModule = (moduleIndex: number) => {
        const updatedLevels = data?.levels?.filter((_:any, index:any) => index !== moduleIndex);
        setData({ ...data, levels: updatedLevels });
    };
    const [parameter, setParameter] = useState<string|null>(null);
    const openSimulator = (moduleIndex: number, caseIndex: number) => {
        if(!user){
            navigate("/login");
            return;
        }

        if(!haveSuscription()){
            alert("No cuentas con suscripcion para este curso");
            navigate("/pricing");
            return;
        }
        setParameter(`?userid=${userData.id}&course=${id}&level=${moduleIndex}&case=${caseIndex}&lan=${localStorage.getItem('language')}`);
        setIsOpenSelectSimulatorModal(true);
    };
    const haveSuscription = ():boolean => {
        let pricingRequired:any[] = pricing.filter((x:any) => { return x?.courses?.includes(id) });
        let subscriptionsActive:any[] = userData?.subscriptions?.filter((x:any) => x.next_billing_time.seconds > Timestamp.now().seconds);        

        if(typeof pricingRequired === "undefined" || typeof subscriptionsActive === "undefined")
            return userData?.isAdmin || userData?.isPremium;
        
        for (const pricing of pricingRequired) {
            for (const suscription of subscriptionsActive) {
                if (pricing.id === suscription.product_id) {
                    return true;
                }
            }
        }
        return userData?.isAdmin || userData?.isPremium;
    }
    const saveData = () =>{
        console.log(data);
        axios.put(`${endpoint}/courses/${id}`, data).catch((error)=>console.error(error));
    }
    
 
    useEffect(onLoadPage, []);

    if(isLoading) return <Loading/>
    return (
        <main className='area course-template'>
            { isAdmin && <div className='save-data'>
                <input type="button" value={t("Save")} onClick={saveData} />
            </div>}
            <div className='header'>
                <div className='image-container'>
                    <img src={process.env.PUBLIC_URL + "/course-template.gif"} alt="Course Template"/>
                    {/* <input type='text' className='text-input' value={data.inputText} onChange={(e) => setData({ ...data, inputText: e.target.value })} /> */}
                </div>
                <div className="data">
                    {isAdmin ? (
                        <textarea
                            className='title'
                            value={data.title}
                            onChange={(e) => setData({ ...data, title: e.target.value })}
                        />
                    ) : (
                        <span className='title'>{data.title}</span>
                    )}
                    {isAdmin ? (
                        <input
                            type='text'
                            className='subtitle'
                            value={data.subtitle}
                            onChange={(e) => setData({ ...data, subtitle: e.target.value })}
                        />
                    ) : (
                        <span className='subtitle'>{data.subtitle}</span>
                    )}
                </div>
            </div>
            <div className='content'>
                {data?.levels?.filter((level:any)=> { return level.active || isAdmin }).map((level: any, moduleIndex: number) => (
                    <ExpandableBox
                        key={moduleIndex}
                        title={level.title}
                        index={moduleIndex + 1}
                        active={level.active}
                        isAdmin={isAdmin}
                        onTitleChange={(newTitle: string) => handleLevelTitleChange(moduleIndex, newTitle)}
                        onActiveChange={(newValue) => handleLevelActiveChange(moduleIndex, newValue)}
                        onDelete={() => { deleteModule(moduleIndex) }}
                    >
                        <div>
                            {level?.cases?.map((_case: any, caseIndex: number) => (
                                <div key={caseIndex} className='case-content'>
                                    <div>
                                        <img
                                            src={process.env.PUBLIC_URL + "/img/play-case-black.png"}
                                            onClick={() => { openSimulator(moduleIndex, caseIndex) }}
                                        />
                                        {isAdmin ? (
                                            <input
                                                type="text"
                                                value={_case.title}
                                                onChange={(e) => {
                                                    const updatedCases = level?.cases?.map((c: any, i: number) => i === caseIndex ? { ...c, title: e.target.value } : c);
                                                    const updatedLevels = data?.levels?.map((l: any, i: number) => i === moduleIndex ? { ...l, cases: updatedCases } : l);
                                                    setData({ ...data, levels: updatedLevels });
                                                }}
                                            />
                                        ) : (
                                            <span>{_case.title}</span>
                                        )}
                                    </div>
                                    {isAdmin && (
                                        <img
                                            src={process.env.PUBLIC_URL + "/img/delete.png"}
                                            alt=""
                                            onClick={() => deleteCase(moduleIndex, caseIndex)}
                                        />
                                    )}
                                </div>
                            ))}
                            {isAdmin && (
                                <div className='add-case' onClick={() => { addCase(moduleIndex) }}>
                                    <img src={process.env.PUBLIC_URL + "/img/add_icon.png"} alt="" />
                                    <span>{t("AddCase")}</span>
                                </div>
                            )}
                        </div>
                    </ExpandableBox>
                ))}
                {isAdmin && (
                    <div className='add-case' onClick={addModule}>
                        <img src={process.env.PUBLIC_URL + "/img/add_icon.png"} alt="" />
                        <span>{t("AddModule")}</span>
                    </div>
                )}
            </div>
                <SimulatorModal parameter={parameter} isOpenSelectSimulatorModal = {isOpenSelectSimulatorModal} onCloseModal={()=>{setIsOpenSelectSimulatorModal(false)}}/>
        </main>
    );
}
