import { Modal } from "./Modal"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SimulatorModalProps {
    parameter:string|null;
    isOpenSelectSimulatorModal: boolean;
    onCloseModal: () => void;
  }

export const SimulatorModal: React.FC<SimulatorModalProps> = ( { parameter, isOpenSelectSimulatorModal, onCloseModal }) => {
    
    const { t } = useTranslation();

    const openCaseBrowser = () => {
        window.open(`https://play.orvo.com.mx/1.1.0/index.html${parameter}`, '_blank');
        onCloseModal();
    }
    const openCaseDesktop = ()=>{
        window.open(`orvo://${parameter}`, '_blank');
        onCloseModal();
    }

    return(
        <>
            <Modal title={"Elige como abrir este caso"} isOpen={isOpenSelectSimulatorModal} onClose={onCloseModal}>
                <div className="modal-content">
                    <input type='button' value={"Versión de escritorio"} onClick={openCaseDesktop}/>
                    <input type='button' value={"Versión de navegador"} onClick={openCaseBrowser}/>
                </div>
            </Modal>
        </>
    )
}